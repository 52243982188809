import { CheckIcon } from "@heroicons/react/solid";
import React from "react";
import BenefitItem from "./BenefitItem";
import { Link } from "react-router-dom";

function Benefits() {
  return (
    <section className="bg-white p-8 sm:p-8 md:p-20">
      <div className="container mx-auto space-y-12 md:space-y-24 px-0 sm:px-40 md:px-40 lg:px-40">
        <BenefitItem
          image="./images/PaieNova_what.png"
          // heading1="Effortless Validation for"
          heading2="Qu'est-ce que PaieNova ?"
          description="PaieNova est la solution révolutionnaire qui unifie la gestion de la paie, des RH, de la comptabilité et des avances sur salaire instantanées, le tout optimisé par l'intelligence artificielle. Automatisez vos processus, éliminez les erreurs et offrez à vos employés une flexibilité financière inégalée."
          Content={() => (
            <div>
              {/* <h5 className="mt-6 mb-2 font-semibold">Accessory makers</h5> */}
              <p>
              Transformez la gestion de votre entreprise et propulsez-la vers de nouveaux sommets avec notre suite complète d'outils.
              </p>
              <div className="mt-8 flex flex-col items-center justify-around sm:flex-row sm:justify-start sm:space-x-4 space-y-4 sm:space-y-0 w-full">
                <Link to="/contact">
                  <button className="primary-button2 text-sm h-14 w-80 sm:w-full md:w-full lg:w-full">
                    Demandez une démo
                  </button>
                </Link>
                <Link to="/contact">
                  <button className="primary-button-white text-sm h-14 w-80 sm:w-full md:w-full lg:w-full">
                    {" "}
                    Créer un compte gratuit
                  </button>
                </Link>
              </div>
            </div>
          )}
        />
        {/* <BenefitItem
          image="./images/benefit-2.svg"
          heading1="Easier decision making for"
          heading2="Customer Support"
          description="The Myspace page defines the individual, his or her characteristics, traits, personal choices and the overall personality of the person."
          Content={() => (
            <div className="flex flex-col space-y-6">
              <CheckedItem
                bgColor="#FF9900"
                text="Never worry about overpaying for your energy again."
              />
              <CheckedItem
                bgColor="#F03E3D"
                text="We will only switch you to energy companies that we trust and will treat you right"
              />
              <CheckedItem
                bgColor="#4D8DFF"
                text="We track the markets daily and know where the savings are."
              />
            </div>
          )}
        /> */}
      </div>
    </section>
  );
}

export default Benefits;

function CheckedItem({ bgColor, text }) {
  return (
    <div>
      <div className="flex items-center space-x-6">
        <div style={{ background: `${bgColor}` }} className="rounded-xl p-1.5">
          <CheckIcon className="h-8 text-white" />
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
}
