import React from "react";
import VideoCard from "./VideoCard";

function BenefitItem({ image, heading2, description, Content }) {
  return (
    <div className="flex w-full flex-col sm:flex-row md:flex-row lg:flex-row even:flex-row justify-between items-center">
      <div className="md:max-w-[50%]">
        {/* <img src={image} alt={heading2} /> */}
        <VideoCard />
      </div>

      <div className="text-left md:max-w-[50%] mt-14 md:mt-0">
        {/* <p className="font-semibold text-base">{heading1}</p> */}
        <h1 className="font-semibold text-3xl md:text-[36.5px] leading-[40px]">{heading2}</h1>
        <p className="my-4">{description}</p>
        <Content/>
      </div>
    </div>
  );
}

export default BenefitItem;
