// src/components/Contact.jsx
import { CheckIcon } from "@heroicons/react/solid";
import emailjs from "emailjs-com";
import React, { useState } from "react";
import { Link } from "react-router-dom";
const Contact = () => {
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [nomEntreprise, setNomEntreprise] = useState("");
  const [urlEntreprise, setUrlEntreprise] = useState("");
  const [tailleEntreprise, setTailleEntreprise] = useState("");
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!prenom) newErrors.prenom = "Prénom est requis";
    if (!nom) newErrors.nom = "Nom est requis";
    if (!email) newErrors.email = "E-mail est requis";
    if (!telephone) newErrors.telephone = "Téléphone est requis";
    if (!nomEntreprise)
      newErrors.nomEntreprise = "Nom de l'entreprise est requis";
    if (!urlEntreprise)
      newErrors.urlEntreprise = "URL de l'entreprise est requis";
    if (!tailleEntreprise)
      newErrors.tailleEntreprise = "Nombre de salariés est requis";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // soumettre le formulaire
      emailjs
        .sendForm(
          "service_ozedw6e",
          "template_s3xs4gx",
          e.target,
          "p8_qb0fKU7N3vid7Y"
        )
        .then(
          () => {
            console.log("SUCCESS!");
            // Réinitialiser le formulaire
            setPrenom("");
            setNom("");
            setEmail("");
            setTelephone("");
            setNomEntreprise("");
            setUrlEntreprise("");
            setTailleEntreprise("");
            setErrors({});
            // notification["success"]({
            //   message: "Success",
            //   description: "Welcome aboard! Your details are received. Get ready to achieve greatness with Eleven Start!",
            // });
          },
          (error) => {
            console.log("FAILED...", error.text);
            // notification["error"]({
            //   message: "Error",
            //   description:
            //     "There was an error sending your message, please try again later.",
            // });
          }
        );
      console.log("Form submitted successfully");
    }
  };

  return (
    <div className="bg-[#213343] text-white mt-20 ">
      <div className="flex flex-row md:flex-row justify-between items-center">
        <img
          src="./images/PaieNova_canvapaienova.svg" // Remplace par le chemin de ton image
          alt="PaieNova"
          className="w-1/4 sm:w-1/4 md:w-1/4 lg:w-[12%] -mt-0.5"
        />
        <img
          src="./images/PaieNova_canvapaienova2.svg" // Remplace par le chemin de ton image
          alt="PaieNova"
          className="w-1/4 sm:w-1/4 md:w-1/4 lg:w-[12%]"
        />
      </div>
      <div className="container mx-auto py-24">
        {/* <header className="mb-10">
          <h1 className="text-3xl font-bold">Le CRM qui va transformer votre entreprise</h1>
          <p className="mt-4 text-lg">
            Ne perdez plus une seule opportunité. Découvrez comment accélérer votre activité avec une démo des logiciels PaieNova.
          </p>
        </header> */}

        <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between">
          <div className="md:w-1/2 md:pr-10">
            <header className="mb-10">
              <h1
                className="text-3xl font-bold mb-10"
                style={{ lineHeight: "1.5" }}
              >
                La solution qui transformera votre entreprise
              </h1>
              <p className="mt-4 text-lg">
                Ne perdez plus de temps ni d'opportunités. Découvrez comment
                optimiser votre gestion avec PaieNova et propulsez votre
                activité grâce à notre démo gratuite.
              </p>
            </header>
            <h2 className="text-2xl font-bold mb-10">
              Fonctionnalités principales
            </h2>
            <div className="mb-12 sm:text-left grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2">
              <div className="sm:ml-0">
                <h3 className="text-xl font-semibold">Ressources Humaines®</h3>
                <ul className="list-disc list-inside space-y-2">
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Gestion intelligente des talents</p>
                  </div>
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Suivi des présences et absences</p>
                  </div>
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Documents RH générés par IA</p>
                  </div>
                </ul>
              </div>
              <div className="">
                <h3 className="text-xl font-semibold">Comptabilité®</h3>
                <ul className="list-disc list-inside space-y-2">
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Comptabilité pilotée par IA</p>
                  </div>
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Connexion aux cabinets comptables</p>
                  </div>
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Évaluation automatisée des risques</p>
                  </div>
                </ul>
              </div>
            </div>

            <div className="mb-12 sm:text-left grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2">
              <div className="sm:ml-0">
                <h3 className="text-xl font-semibold">Paie®</h3>
                <ul className="list-disc list-inside space-y-2">
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Bulletins de paie électroniques</p>
                  </div>
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Paie automatisée intelligente</p>
                  </div>
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Déclarations fiscales et sociales</p>
                  </div>
                </ul>
              </div>
              <div className="">
                <h3 className="text-xl font-semibold">Avances sur salaire®</h3>
                <ul className="list-disc list-inside space-y-2">
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Avances instantanées en un clic</p>
                  </div>
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Intégration automatisée fluide</p>
                  </div>
                  <div className="flex items-center space-x-6">
                    <div
                      style={{ background: "#263BE3" }}
                      className="rounded-xl p-0.5 mt-4"
                    >
                      <CheckIcon className="h-4 text-white" />
                    </div>
                    <p className="mt-4">Suivi en temps réel des transactions</p>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          <div className="md:w-1/2 bg-white text-gray-900 py-12 p-6 -sm:mt-24 rounded-lg shadow-lg mb-24">
            <form className="space-y-5" onSubmit={handleSubmit}>
              <div className="flex justify-between items-start gap-4">
                <div className="flex-1">
                  <label htmlFor="prenom" className="block text-sm font-medium">
                    Prénom *
                  </label>
                  <input
                    type="text"
                    id="prenom"
                    name="prenom"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  {errors.prenom && (
                    <p className="text-red-600 text-sm">{errors.prenom}</p>
                  )}
                </div>
                <div className="flex-1">
                  <label htmlFor="nom" className="block text-sm font-medium">
                    Nom *
                  </label>
                  <input
                    type="text"
                    id="nom"
                    name="nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  {errors.nom && (
                    <p className="text-red-600 text-sm">{errors.nom}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-start gap-4">
                <div className="flex-1">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium"
                    >
                      E-mail *
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors.email && (
                      <p className="text-red-600 text-sm">{errors.email}</p>
                    )}
                  </div>
                </div>

                <div className="flex-1">
                  <div>
                    <label
                      htmlFor="telephone"
                      className="block text-sm font-medium"
                    >
                      Téléphone *
                    </label>
                    <input
                      type="tel"
                      id="telephone"
                      name="telephone"
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors.telephone && (
                      <p className="text-red-600 text-sm">{errors.telephone}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-start gap-4">
                <div className="flex-1">
                  <div>
                    <label
                      htmlFor="nom-entreprise"
                      className="block text-sm font-medium"
                    >
                      Nom de l'entreprise *
                    </label>
                    <input
                      type="text"
                      id="nom-entreprise"
                      name="nomEntreprise"
                      value={nomEntreprise}
                      onChange={(e) => setNomEntreprise(e.target.value)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors.nomEntreprise && (
                      <p className="text-red-600 text-sm">
                        {errors.nomEntreprise}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex-1">
                  <div>
                    <label
                      htmlFor="url-entreprise"
                      className="block text-sm font-medium"
                    >
                      URL de l'entreprise *
                    </label>
                    <input
                      type="url"
                      id="url-entreprise"
                      name="urlEntreprise"
                      value={urlEntreprise}
                      onChange={(e) => setUrlEntreprise(e.target.value)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors.urlEntreprise && (
                      <p className="text-red-600 text-sm">
                        {errors.urlEntreprise}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="taille-entreprise"
                  className="block text-sm font-medium"
                >
                  Nombre de salariés dans l'entreprise *
                </label>
                <select
                  id="taille-entreprise"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="tailleEntreprise"
                  value={tailleEntreprise}
                  onChange={(e) => setTailleEntreprise(e.target.value)}
                >
                  <option value="">Veuillez sélectionner</option>
                  <option value="1-10">1-10</option>
                  <option value="11-50">11-50</option>
                  <option value="51-200">51-200</option>
                  <option value="201-500">201-500</option>
                  <option value="500+">500+</option>
                </select>
                {errors.tailleEntreprise && (
                  <p className="text-red-600 text-sm">
                    {errors.tailleEntreprise}
                  </p>
                )}
              </div>
              <p className="text-gray-600 mt-8">
                La protection des données nous tient à cœur. PaieNova utilise les
                informations que vous fournissez afin de vous proposer des
                informations et du contenu pertinent sur nos produits et
                services. Vous pouvez vous désinscrire de ce type de
                communications à tout moment. Pour plus d'informations,
                consultez notre{" "}
                <Link className="text-blue-500 hover:underline" to="/privacy">
                  politique de confidentialité
                </Link>{" "}
                et nos{" "}
                <Link className="text-blue-500 hover:underline" to="/cgu">
                  CGU
                </Link>
              </p>
              <div className="py-4">
                <button
                  type="submit"
                  className="primary-button2 py-3 text-sm w-full"
                >
                  Obtenir une démonstration
                </button>
              </div>
              {/* <button type="submit" className="w-full bg-orange-600 text-white py-2 px-4 rounded-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                Obtenir une démonstration
              </button> */}
            </form>
          </div>
        </div>

        <hr className="mt-20" />
        <div className="mt-10 text-center">
          <p className="text-lg">
          Les entreprises de tous horizons choisissent PaieNova pour simplifier et optimiser la gestion de la paie, des RH, de la comptabilité et des avances sur salaire instantanées. Adoptez une solution intuitive et innovante pour transformer votre gestion d'entreprise.
          </p>
        </div>
      </div>

      <div className="bg-[#E6FAFF] text-black">
        <div className="max-w-7xl m-auto p-16 px-8 sm:px-12 mt-0 md:flex flex-row justify-between items-center">
          <div className="md:max-w-[40%]">
            <h2 className="text-2xl font-bold mb-6">
              Adoptez PaieNova pour une gestion simplifiée et optimisée
            </h2>
            <p className="text-lg mb-6">
              Automatisez la paie, les RH, la comptabilité et les avances sur
              salaire instantanées avec PaieNova, la solution tout-en-un
              optimisée par l'IA. Découvrez comment PaieNova peut transformer
              votre entreprise en demandant une démonstration gratuite dès
              aujourd'hui.
            </p>
          </div>
          <div className="md:max-w-[60%]">
            <img
              src="./images/PaieNova_end.png" // Remplace par le chemin de ton image
              alt="PaieNova"
              className="rounded-lg mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
