import React from "react";
import { Link } from "react-router-dom";
function BottomCTA2() {
  return (
    // parent div
    <section className="bg-[#FFF] mt-0">
      <main className="max-w-7xl m-auto p-24 px-8 sm:px-12 mt-0 md:flex flex-row-reverse justify-between items-center">
        <div className="md:max-w-[40%]">
          <img
            src="./images/PaieNova_end.png"
            alt="PaieNova_end"
            className="md:block"
            // className="hidden md:block hover:bg-[#00CCFF]"
            width="120%"
          />
        </div>

        {/* text section */}
        <div className="text-center sm:text-left md:text-left md:max-w-[50%]">
          <p className="mt-4 text-[22px] leading-[28px] font-bold">
            Accélerer votre croissance dès aujourd'hui avec PaieNova
          </p>
          <div className="mt-8 flex flex-col items-center justify-around sm:flex-row sm:justify-start sm:space-x-4 space-y-4 sm:space-y-0">
            <Link to="/contact">
              <button className="primary-button2">Demandez une démo</button>
            </Link>
            <Link to="https://app.paienova.com/">
              <button className="primary-button-white">
                {" "}
                Essayez gratuitement
              </button>
            </Link>
          </div>
        </div>
      </main>
    </section>
  );
}

export default BottomCTA2;
