import { CheckIcon } from "@heroicons/react/solid";
import React from "react";
import { Link } from "react-router-dom";

function PriceCard({ title, description, features = [] }) {
  return (
    // <div className="border min-w-[80vw] sm:min-w-[400px] md:min-w-full group even:bg-primary even:text-white flex flex-col p-7 rounded-xl shadow-borderShadow">
    <div className="bg-white border min-w-[80vw] max-w-[968px] sm:min-w-[400px] hover:bg-gray-100 md:min-w-full flex flex-col p-7 rounded-xl shadow-borderShadow">
      <div className="flex">
        <span className="mt-4 mr-3">
          <img
            src={
              title === "Ressources Humaines®"
                ? "./images/PaieNova_HR_icon.png"
                : title === "Paie®"
                ? "./images/PaieNova_Payroll_icon.png"
                : title === "Comptabilité®"
                ? "./images/PaieNova_Accounting_icon.png"
                : "./images/PaieNova_Salary_advances_icon.png"
            }
            alt="PaieNova"
            className="w-11 h-11"
          />
        </span>
        <h4 className="mt-6 font-semibold flex text-2xl">{title}</h4>
      </div>
      {title === "Comptabilité®" ? (
        <p className="mt-2 items-start h-36">{description}</p>
      ) : (
        <p className="mt-2 items-start">{description}</p>
      )}
      {/* <div className="mt-6 flex items-center">
        <p className="text-7xl text-primary group-even:text-white font-bold">
          {price}
        </p>
        <div className="ml-2 flex flex-col items-start">
          <p className="text-primary group-even:text-white font-bold text-2xl">
            $
          </p>
          <p className="text-[#AFAFAF] group-even:text-[#E0E0E0] -mt-1">
            Per / month
          </p>
        </div>
      </div> */}
      <hr style={{ borderTop: "1px solid #ccc", margin: "20px 0" }} />
      <div>
        <p className="items-start">FONCTIONNALITÉS PRINCIPALES</p>
        {features.map((feature) => (
          <div className="flex items-center space-x-6">
            <div
              style={{ background: "#263BE3" }}
              className="rounded-xl p-0.5 mt-4"
            >
              <CheckIcon className="h-4 text-white" />
            </div>
            <p key={feature} className="mt-4">
              {feature}
            </p>
          </div>
        ))}
      </div>

      <Link to="/contact">
        <button className="primary-button mt-9 group-even:primary-button-white w-full">
          En savoir plus
        </button>
      </Link>
    </div>
  );
}

export default PriceCard;
