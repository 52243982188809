import React from "react";

const Modal = ({ isOpen, onClose, videoSrc }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div className="relative w-full max-w-4xl p-8 rounded-lg">
        <button
          className="absolute rounded-full bg-black bg-opacity-50 w-8 h-8 hover:bg-opacity-75 transition duration-300 ease-in-out top-0 right-0 text-white hover:text-gray-600"
          onClick={onClose}
        >
          ✕
        </button>
        {/* <video className="w-full" controls autoPlay>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        <div className="w-full aspect-w-16 aspect-h-9">
          <iframe
            className="w-full h-full"
            src={videoSrc}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Modal;
