import React, { createContext, useContext, useState } from "react";

const LangContext = createContext();

export const useLang = () => {
  return useContext(LangContext);
};

export const LangProvider = ({ children }) => {
  const [isLangOpen, setIsLangOpen] = useState(false);

  return (
    <LangContext.Provider value={{ isLangOpen, setIsLangOpen }}>
      {children}
    </LangContext.Provider>
  );
};
