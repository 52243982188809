import React, { createContext, useContext, useState } from "react";

const SoftwareContext = createContext();

export const useSoftware = () => {
  return useContext(SoftwareContext);
};

export const SoftwareProvider = ({ children }) => {
  const [isSoftwareMenuOpen, setIsSoftwareMenuOpen] = useState(false);

  return (
    <SoftwareContext.Provider
      value={{ isSoftwareMenuOpen, setIsSoftwareMenuOpen }}
    >
      {children}
    </SoftwareContext.Provider>
  );
};
