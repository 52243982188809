import React from "react";
import BlogItem from "./BlogItem";

function Blogs() {
  return (
    <section className="bg-[#F6F8FF] p-8 sm:p-12 md:p-24" id="ressources">
      <div className="container text-center md:text-center flex flex-col items-center">
        <h2 className="text-2xl font-bold">
          Solutions pour toutes les entreprises avec PaieNova
        </h2>
        <p className="font-medium mt-4 w-[70%] items-center">
          Découvrez comment PaieNova peut transformer la gestion de votre
          entreprise avec des solutions innovantes et intégrées.
        </p>
        <div className="mt-8 grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
          <BlogItem
            imgSrc="images/PaieNova-blog-1.jpg"
            title="Générez des salaires précis et ponctuels"
          
            description="Automatisez la paie pour réduire les erreurs et garantir la satisfaction des employés grâce à une solution fiable et efficace."
          />
          <BlogItem
            imgSrc="images/PaieNova-blog-2.jpg"
            title="Optimisez vos ressources humaines"
          
            description="Utilisez l'IA pour gérer les absences, congés et performances tout en assurant la conformité réglementaire."
          />
          <BlogItem
            imgSrc="images/PaieNova-blog-3.jpg"
            title="Simplifiez vos finances"
          
            description="Suivez les dépenses, gérez les factures et accédez à des rapports financiers détaillés pour une prise de décision éclairée."
          />
          <BlogItem
            imgSrc="images/PaieNova-blog-4.jpg"
            title="Offrez flexibilité et tranquillité d'esprit"
          
            description="Proposez des avances sur salaire instantanées pour améliorer la satisfaction et la fidélité de vos employés."
          />
        </div>

        {/* <button className="primary-button mt-10 text-sm">View More</button> */}
      </div>
    </section>
  );
}

export default Blogs;
