import React from "react";
import TestimonialItem from "./TestimonialItem";

function Testimonials() {
  // const testimonial =
  //   "PaieNova helps you see how many more days you need to work to reach your financial goal.";
  return (
    <div className="bg-[#E6FAFF] p-8 sm:p-12 md:p-24">
      <section className="container text-center flex flex-col items-center">
        <h3 className="text-3xl font-semibold">
          Une solution intuitive avec une équipe à votre service
        </h3>
        <p className="font-medium mt-8 max-w-lg">
          Découvrez comment PaieNova simplifie la gestion d'entreprise et vous
          accompagne dans votre croissance grâce à nos outils et services.{" "}
        </p>

        <div className="mt-12 min-w-[80vw] justify-center md:gap-4 md:min-w-full grid gap-8 md:grid-cols-3">
          <TestimonialItem
            name="Support Client en Continu"
            designation="Assistance dédiée 24/7"
            userImg="./images/PaieNova_support_client.png"
            // rating={4}
            testimonial="Obtenez de l'aide à tout moment via téléphone, chat ou e-mail avec notre équipe de support dédiée."
          />
          <TestimonialItem
            name="Services PaieNova"
            designation="Onboarding personnalisé"
            userImg="./images/PaieNova_onbording.png"
            // rating={3}
            testimonial="Démarrez rapidement avec un processus d'intégration sur mesure pour votre entreprise."
          />
          <TestimonialItem
            name="Outils et Ressources Avancés"
            designation="Optimisez votre gestion"
            userImg="./images/PaieNova_optimisation.png"
            // rating={4}
            testimonial="Accédez à une bibliothèque de guides pratiques, modèles personnalisables et e-books pour maximiser l'utilisation de PaieNova et améliorer l'efficacité de vos processus."
          />
        </div>
      </section>
    </div>
  );
}

export default Testimonials;
