import React from "react";

function Cgu() {
  return (
    <section className="container mt-40 text-[18px] mb-24">
      <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-semibold">
        Conditions Générales d'Utilisation (CGU)
      </h1>
      <p className="mt-6 mb-2 font-bold">1. Introduction</p>
      <p>
        Bienvenue sur PaieNova. En accédant et en utilisant notre site web, vous
        acceptez de vous conformer aux présentes Conditions Générales
        d'Utilisation (CGU). Ces CGU régissent l'utilisation de notre site et de
        nos services. Veuillez les lire attentivement.
      </p>
      <p className="mt-6 mb-2 font-bold">2. Acceptation des CGU</p>
      <p>
        En utilisant ce site, vous confirmez avoir lu, compris et accepté ces
        CGU. Si vous n'acceptez pas ces conditions, veuillez cesser d'utiliser
        notre site.
      </p>
      <p className="mt-6 mb-2 font-bold">3. Modifications des CGU</p>
      <p>
        Nous nous réservons le droit de modifier ces CGU à tout moment. Les
        modifications prendront effet dès leur publication sur le site. Il est
        de votre responsabilité de consulter régulièrement les CGU pour rester
        informé des éventuelles modifications.
      </p>
      <p className="mt-6 mb-2 font-bold">4. Utilisation du Site</p>
      <p>
        Vous acceptez d'utiliser notre site uniquement à des fins légales et
        conformément aux CGU. Vous vous engagez à ne pas :
      </p>
      
        <ul class="list-disc list-inside ml-5 text-left">
          <li>
            Utiliser le site de manière à porter atteinte à nos droits ou ceux
            de tiers.
          </li>
          <li>Perturber le fonctionnement du site.</li>
          <li>Accéder à des parties du site qui ne vous sont pas destinées.</li>
        </ul>
      
      <p className="mt-6 mb-2 font-bold">5. Propriété Intellectuelle</p>
      <p>
        Tout le contenu présent sur le site (textes, images, logos, etc.) est
        protégé par des droits de propriété intellectuelle. Vous n'êtes pas
        autorisé à utiliser, reproduire ou distribuer ces contenus sans notre
        autorisation préalable écrite.
      </p>
      <p className="mt-6 mb-2 font-bold">
        6. Confidentialité et Protection des Données
      </p>
      <p>
        Nous accordons une grande importance à la protection de vos données
        personnelles. Notre politique de confidentialité, accessible sur notre
        site, décrit comment nous collectons, utilisons et protégeons vos
        informations personnelles.
      </p>
      <p className="mt-6 mb-2 font-bold">7. Limitation de Responsabilité</p>
      <p>
        Nous nous efforçons de garantir l'exactitude des informations présentes
        sur notre site. Cependant, nous ne pouvons pas garantir que celles-ci
        soient toujours à jour ou exemptes d'erreurs. Nous ne saurions être
        tenus responsables des dommages directs ou indirects résultant de
        l'utilisation du site.
      </p>
      <p className="mt-6 mb-2 font-bold">8. Liens vers des Sites Tiers</p>
      <p>
        Notre site peut contenir des liens vers des sites tiers. Nous ne sommes
        pas responsables du contenu ou des pratiques de ces sites. L'inclusion
        de ces liens ne constitue pas une approbation de notre part.
      </p>
      <p className="mt-6 mb-2 font-bold">9. Droit Applicable</p>
      <p>
        Ces CGU sont régies par le droit en vigueur au Bénin. Tout litige
        relatif à l'interprétation ou à l'exécution de ces CGU sera de la
        compétence exclusive des tribunaux béninois.
      </p>
      <p className="mt-6 mb-2 font-bold">10. Contact</p>
      <p>
        Pour toute question relative à ces CGU, vous pouvez nous contacter à
        l'adresse suivante :{" "}
        <a href="mailto:contact@paienova.com">contact@paienova.com.</a>
      </p>
      <p className="mt-6 mb-2 font-bold">11. Acceptation des CGU</p>
      <p>
        En utilisant notre site, vous reconnaissez avoir lu et compris ces CGU,
        et vous acceptez d'être lié par celles-ci.
      </p>
      <p className="mt-6 mb-2 font-bold">
        12. Responsabilités de l'Utilisateur
      </p>
      <p>En tant qu'utilisateur, vous êtes responsable de :</p>

      <ul class="list-disc list-inside ml-5 text-left">
        <li>Maintenir la confidentialité de vos informations de compte.</li>
        <li>Assurer que les informations fournies sont exactes et à jour.</li>
        <li>
          Utiliser le site conformément à toutes les lois et réglementations
          applicables.
        </li>
      </ul>

      <p className="mt-6 mb-2 font-bold">13. Sécurité</p>
      <p>
        Nous avons mis en place des mesures de sécurité techniques et
        organisationnelles pour protéger vos données personnelles contre la
        perte, l'utilisation abusive, l'accès non autorisé, la divulgation ou la
        modification.
      </p>
      <p className="mt-6 mb-2 font-bold">14. Suspension et Résiliation</p>
      <p>
        Nous nous réservons le droit de suspendre ou de résilier votre accès au
        site à notre discrétion, sans préavis, en cas de violation de ces CGU ou
        pour toute autre raison jugée appropriée.
      </p>
      <p className="mt-6 mb-2 font-bold">15. Dispositions Générales</p>
      <p>
        Ces CGU constituent l'intégralité de l'accord entre vous et PaieNova
        concernant l'utilisation du site. Si une disposition de ces CGU est
        jugée invalide par un tribunal compétent, les autres dispositions
        resteront en vigueur.
      </p>
      <p className="mt-6 mb-2 font-bold">16. Accord de Preuve</p>
      <p>
        Les registres informatisés conservés dans nos systèmes informatiques
        dans des conditions raisonnables de sécurité seront considérés comme des
        preuves des communications, des commandes et des paiements intervenus
        entre les parties.
      </p>
    </section>
  );
}

export default Cgu;
