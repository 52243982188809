import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import React, { useState } from "react";

const BottomCTA = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(null); // pour suivre la direction de la transition

  const items = [
    {
      title: "Tranquillité Financière",
      description:
        "Offrez à vos employés une tranquillité d'esprit avec notre nouvelle avance sur salaire instantanée. Simplifiez les processus financiers et améliorez la satisfaction en offrant un accès rapide et flexible aux fonds quand ils en ont besoin.",
      image: "./images/salary_advances_wao.png", // Remplace par le chemin de ton image
      link: "/contact", // Remplace par le lien approprié
    },
    {
      title: "RH Révolution",
      description:
        "Générez vos documents RH instantanément grâce à notre IA avancée. Automatisez, simplifiez et gagnez du temps tout en restant conforme.",
      image: "./images/HR_revolution.png", // Remplace par le chemin de ton image
      link: "/contact", // Remplace par le lien approprié
    },
    // Ajoute d'autres éléments ici si nécessaire
  ];

  const handlePrev = () => {
    setDirection("prev");

    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setDirection("next");

    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="bg-[#E9EBFC] py-12 sm:py-24 md:py-24 mt-0 sm:mt-0">
      <div className="container mx-auto px-4 md:px-10 lg:px-36 max-w-7xl">
        <h2 className="text-2xl text-center font-bold mb-10">
          Nouveautés PaieNova
        </h2>
        <div className="relative h-100 overflow-hidden sm:h-82">
          <div className="overflow-hidden">
            {items.map((item, index) => (
              <div
                key={index}
                className={`${
                  //   index === currentIndex ? "block translate-x-0" : "hidden"
                  // } ${
                  //   index === currentIndex && direction === "next"
                  //     ? "animate-scroll-next"
                  //     : index === currentIndex && direction === "prev"
                  //     ? "animate-scroll-prev"
                  //     : ""
                  index === currentIndex
                    ? direction === "next"
                      ? "animate-scroll-next"
                      : direction === "prev"
                      ? "animate-scroll-prev"
                      : ""
                    : "hidden-exit"
                } flex`}
              >
                <div className="p-6 rounded-lg flex flex-col items-center md:flex-row slide">
                  <div className="md:w-1/2 md:pl-10 md:mb-0">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="md:w-1/2 md:pl-6 pr-4 sm:pr-10 md:pr-10 lg:pr-10 md:absolute md:right-0">
                    <h3 className="text-xl text-center sm:text-left md:text-left font-semibold mb-2">
                      {item.title}
                    </h3>
                    <p className="mb-4">{item.description}</p>
                    <a
                      href={item.link}
                      className="text-blue-500 hover:underline"
                    >
                      En savoir plus
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Flèches de navigation */}
          <button
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white hover:bg-gray-300 p-2 rounded-full shadow"
            onClick={handlePrev}
          >
            <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
          </button>
          <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white hover:bg-gray-300 p-2 rounded-full shadow"
            onClick={handleNext}
          >
            <ChevronRightIcon className="h-6 w-6 text-gray-600" />
          </button>
        </div>

        {/* Points de pagination */}
        <div className="flex justify-center mt-4">
          {items.map((_, index) => (
            <span
              key={index}
              className={`h-4 w-4 mx-1 rounded-full ${
                index === currentIndex ? "bg-gray-800" : "bg-gray-400"
              }`}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomCTA;
