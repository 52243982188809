import React from "react";

function Politique() {
  return (
    <section className="container mt-40 text-[18px] mb-24">
      <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-semibold">
        Politique de confidentialité
      </h1>
      <p className="mt-6 mb-2 font-bold">1. Introduction</p>
      <p>
        Bienvenue sur PaieNova. Nous accordons une grande importance à votre vie
        privée et nous engageons à protéger vos données personnelles. Cette
        Politique de Confidentialité explique comment nous collectons, utilisons
        et protégeons vos informations lorsque vous utilisez notre site web et
        nos services. Veuillez lire attentivement cette politique.
      </p>
      <p className="mt-6 mb-2 font-bold">2. Collecte de Données</p>
      <p>
        Nous collectons les données personnelles que vous nous fournissez
        directement, notamment lorsque vous :
      </p>

      <ul class="list-disc list-inside ml-5 text-left">
        <li>Vous inscrivez à un compte</li>
        <li>Vous abonnez à notre newsletter</li>
        <li>Remplissez un formulaire de contact</li>
        <li>Effectuez un achat</li>
        <li>Participez à des enquêtes ou promotions</li>
      </ul>

      <p>
        Les données personnelles que nous collectons peuvent inclure votre nom,
        adresse email, numéro de téléphone, adresse postale, informations de
        paiement, et d'autres détails pertinents nécessaires pour fournir nos
        services.
      </p>
      <p className="mt-6 mb-2 font-bold">3. Utilisation des Données</p>
      <p>Nous utilisons vos données personnelles pour :</p>

      <ul class="list-disc list-inside ml-5 text-left">
        <li>Fournir et gérer nos services</li>
        <li>Traiter les transactions et envoyer les informations associées</li>
        <li>
          Communiquer avec vous, y compris répondre à vos demandes et envoyer
          des mises à jour
        </li>
        <li>Améliorer notre site web et nos services</li>
        <li>Personnaliser votre expérience</li>
        <li>Mener des activités de marketing et de promotion</li>
        <li>Respecter les obligations légales</li>
      </ul>

      <p className="mt-6 mb-2 font-bold">4. Partage des Données</p>
      <p>
        Nous ne vendons pas vos données personnelles à des tiers. Cependant,
        nous pouvons partager vos informations avec :
      </p>

      <ul class="list-disc list-inside ml-5 text-left">
        <li>
          Des prestataires de services qui nous aident à exploiter notre site
          web et nos services, tels que les processeurs de paiements et les
          fournisseurs de services d'email
        </li>
        <li>
          Des partenaires commerciaux à des fins de marketing et de promotion
        </li>
        <li>
          Les autorités légales si requis par la loi ou pour protéger nos droits
        </li>
      </ul>

      <p>
        Tous les tiers avec lesquels nous partageons vos données sont tenus de
        protéger vos informations et de les utiliser uniquement aux fins que
        nous spécifions.
      </p>
      <p className="mt-6 mb-2 font-bold">5. Protection des Données</p>
      <p>
        Nous mettons en œuvre diverses mesures de sécurité pour maintenir la
        sécurité de vos données personnelles, notamment :
      </p>

      <ul class="list-disc list-inside ml-5 text-left">
        <li>Le chiffrement des informations sensibles</li>
        <li>Des évaluations régulières de sécurité</li>
        <li>
          Des contrôles d'accès pour restreindre l'accès aux données aux seules
          personnes autorisées
        </li>
        <li>Des mises à jour régulières de nos pratiques de sécurité</li>
      </ul>

      <p className="mt-6 mb-2 font-bold">6. Cookies et Technologies de Suivi</p>
      <p>
        Notre site web utilise des cookies et des technologies similaires pour
        améliorer votre expérience de navigation. Les cookies sont de petits
        fichiers qu'un site ou son fournisseur de services transfère sur le
        disque dur de votre ordinateur via votre navigateur web. Ils permettent
        au site de reconnaître votre navigateur et de capturer certaines
        informations. Vous pouvez choisir de désactiver les cookies via les
        paramètres de votre navigateur. Cependant, la désactivation des cookies
        peut affecter la fonctionnalité de notre site.
      </p>

      <p className="mt-6 mb-2 font-bold">7. Vos Droits</p>
      <p>Vous avez le droit de :</p>

      <ul class="list-disc list-inside ml-5 text-left">
        <li>Accéder aux données personnelles que nous détenons sur vous</li>
        <li>
          Demander des corrections pour des données inexactes ou incomplètes
        </li>
        <li>Demander la suppression de vos données personnelles</li>
        <li>Vous opposer ou restreindre le traitement de vos données</li>
        <li>Retirer votre consentement au traitement des données</li>
        <li>Demander la portabilité des données</li>
      </ul>

      <p>
        Pour exercer ces droits, veuillez nous contacter à :{" "}
        <a href="mailto:contact@paienova.com">contact@paienova.com.</a>
      </p>
      <p className="mt-6 mb-2 font-bold">8. Conservation des Données</p>
      <p>
        Nous conservons vos données personnelles uniquement aussi longtemps que
        nécessaire pour atteindre les objectifs pour lesquels elles ont été
        collectées, y compris les exigences légales, comptables ou de reporting.
      </p>
      <p className="mt-6 mb-2 font-bold">9. Liens vers des Sites Tiers</p>
      <p>
        Notre site peut contenir des liens vers des sites tiers. Nous ne sommes
        pas responsables des pratiques de confidentialité ou du contenu de ces
        sites externes. Nous vous encourageons à lire les politiques de
        confidentialité des sites que vous visitez.
      </p>
      <p className="mt-6 mb-2 font-bold">10. Confidentialité des Enfants</p>
      <p>
        Nos services ne sont pas destinés aux personnes de moins de 18 ans. Nous
        ne collectons pas sciemment de données personnelles auprès d'enfants. Si
        nous prenons conscience d'avoir collecté des données personnelles d'un
        enfant de moins de 18 ans, nous prendrons des mesures pour supprimer ces
        informations rapidement.
      </p>
      <p className="mt-6 mb-2 font-bold">
        11. Modifications de Cette Politique
      </p>
      <p>
        Nous pouvons mettre à jour cette Politique de Confidentialité de temps à
        autre. Toute modification sera publiée sur cette page avec une date de
        révision mise à jour. Nous vous encourageons à consulter régulièrement
        cette politique pour rester informé de la manière dont nous protégeons
        vos données.
      </p>
      <p className="mt-6 mb-2 font-bold">12. Contactez-Nous</p>
      <p>
        Si vous avez des questions ou des préoccupations concernant cette
        Politique de Confidentialité ou nos pratiques de gestion des données,
        veuillez nous contacter à :
      </p>
      <p>
        Email: <a href="mailto:contact@paienova.com">contact@paienova.com</a>
      </p>
      <p>Adresse: BP7298 - C’165 Missessin Cotonou Bénin</p>
      <p>
        En utilisant notre site, vous consentez à notre Politique de
        Confidentialité.
      </p>
    </section>
  );
}

export default Politique;
