import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToSection from "./components/ScrollToSection";
import { LangProvider } from "./contexts/LangContext";
import { SoftwareProvider } from "./contexts/SoftwareContext";
import Cgu from "./pages/Cgu";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Politique from "./pages/Politique";

function App() {
  return (
    <LangProvider>
      <SoftwareProvider>
        <Router>
          <ScrollToSection />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Politique />} />
            <Route path="/cgu" element={<Cgu />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </Router>
      </SoftwareProvider>
    </LangProvider>
  );
}

export default App;
