import React from "react";
import { Link } from "react-router-dom";
function Hero() {
  return (
    // parent div
    <section className="bg-[#E6FAFF] sm:px-12 mt-20">
      <main className="max-w-7xl m-auto p-24 px-8 sm:px-12 mt-14 md:flex flex-row-reverse justify-between items-center">
        <div className="md:max-w-[58%]">
          <img
            src="./images/PaieNova_realShow.png"
            alt="hero"
            className="block md:block pb-10 sm:pb-0"
            // className="hidden md:block hover:bg-[#00CCFF]"
            width="120%"
          />
        </div>

        {/* text section */}
        <div className="text-left sm:text-left md:max-w-[40%]">
          <h1 className="font-bold sm:text-4xl text-2xl sm:leading-[50px]">
            Unifiez et Simplifiez Votre Gestion d'Entreprise avec PaieNova
          </h1>
          <p className="mt-4 text-[18px] leading-[28px] font-normal">
            La solution logicielle ultime pour une gestion unifiée de la paie,
            RH, comptabilité et avances sur salaire instantanées, optimisée par
            l'IA.
          </p>
          <div className="mt-8 flex flex-col items-center justify-around sm:flex-row sm:justify-start sm:space-x-4 space-y-4 sm:space-y-0">
            <Link to="/contact">
              <button className="primary-button2 text-sm">
                Demandez une démo
              </button>
            </Link>
            <Link to="https://app.paienova.com/">
              <button className="primary-button-white text-sm">
                {" "}
                Essayez gratuitement
              </button>
            </Link>
            {/* <p className="font-semibold text-primary whitespace-nowrap flex items-center underline hover:scale-110 active:scale-95 duration-200 cursor-pointer text-sm">
            <PlayIcon className="h-8" />
            Demandez une démo
          </p> */}
          </div>
          <p className="mt-8 text-[15px] leading-[28px] font-normal">
            Obtenez une démonstration de la version premium ou faites vos
            premiers pas avec les outils gratuits.
          </p>
        </div>
      </main>
    </section>
  );
}

export default Hero;
