import React, { useState } from "react";
import Modal from "./Modal";

const VideoCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePlayVideo = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col items-center space-y-4 sm:w-full">
      <div className="relative w-[380px] sm:w-full p-6 sm:p-6 md:p-6 lg:p-6 text-center">
        <img
          src="./images/PaieNova_what.png"
          alt="Video card img"
          className="w-96 sm:max-w-md sm:max-h-md md:w-full md:h-full"
        />
        {/* <button
          className="absolute inset-0 flex items-center justify-center bg-[#263BE3] bg-opacity-70 text-white text-2xl rounded-full w-16 h-16 hover:bg-opacity-100 transition duration-300 ease-in-out"
          onClick={handlePlayVideo}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          ▶
        </button> */}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        videoSrc="https://www.youtube.com/embed/5KMZlhPpoB0"
      />
    </div>
  );
};

export default VideoCard;
