import React from "react";
import { Link } from "react-router-dom";
import PriceCard from "./PriceCard";
function PriceTable() {
  return (
    <section className="bg-[#E6FAFF] p-8 sm:p-12 md:p-24">
      <div className="container mt-0 max-w-[968px] mx-auto px-4">
        <img
          className="mt-6 md:mt-0 w-full text-center"
          src="./images/PaieNova_value_1.png"
          alt="PaieNova image"
        />
        <h3 className="text-2xl sm:text-3xl md:text-[32px] mt-8 font-bold text-center">
          Une plateforme unifiée pour optimiser la gestion de votre entreprise.
        </h3>
        <p className="font-medium mt-8 text-center">
          PaieNova intègre la gestion de la paie, des ressources humaines, de la
          comptabilité et des avances sur salaire dans une seule et même
          plateforme. Chaque module est connecté à une base de données
          centralisée, vous offrant un accès en temps réel à toutes les
          informations cruciales de vos employés et de votre entreprise. Cette
          synergie permet une gestion efficace, une élimination des erreurs et une
          amélioration de la productivité. Individuellement puissants, ces
          modules atteignent leur plein potentiel lorsqu'ils sont utilisés
          ensemble, transformant votre gestion d'entreprise en une expérience
          fluide et intégrée.
        </p>

        <div className="mx-auto mt-12 max-w-full md:max-w-[65%] grid gap-4 sm:gap-6 md:gap-8 grid-cols-1 md:grid-cols-2">
          <Link to="/contact">
            <button className="primary-button2 w-full">
              Demandez une démo
            </button>
          </Link>
          <Link to="https://app.paienova.com/">
            <button className="primary-button-white w-full">
              Essayez gratuitement
            </button>
          </Link>
        </div>
        <div id="pricing" className="mx-auto max-w-7xl mt-24 grid gap-8 sm:gap-10 md:gap-5 lg:gap-8 xl:gap-16 grid-cols-1 md:grid-cols-2 justify-center">
          <PriceCard
            title="Ressources Humaines®"
            description="Un logiciel de gestion des ressources humaines optimisé par l'IA pour attirer, recruter et fidéliser les meilleurs talents. Assurez une gestion précise des présences et des absences, et automatisez la création de documents RH essentiels."
            features={[
              "Gestion intelligente des talents",
              "Suivi des présences et absences",
              "Documents RH générés par IA",
            ]}
          />
          <PriceCard
            title="Comptabilité®"
            description="Un logiciel comptable optimisé par l'IA qui automatise les tâches répétitives, offre des analyses financières en temps réel et s'intègre facilement à vos systèmes existants pour une gestion fluide et efficace."
            features={[
              "Comptabilité pilotée par IA",
              "Connexion aux cabinets comptables",
              "Évaluation automatisée des risques",
            ]}
          />
        </div>
        <div className="mx-auto max-w-7xl mt-11 grid gap-8 sm:gap-10 md:gap-5 lg:gap-8 xl:gap-16 grid-cols-1 md:grid-cols-2 justify-center">
          <PriceCard
            title="Paie®"
            description="Un logiciel de gestion de la paie révolutionnaire qui offre des bulletins de paie électroniques sécurisés, automatise les processus de paie et gère efficacement les déclarations fiscales et sociales pour une conformité sans faille."
            features={[
              "Bulletins de paie électroniques",
              "Paie automatisée intelligente",
              "Déclarations fiscales et sociales",
            ]}
          />
          <PriceCard
            title={
              <div className="flex items-center relative">
                <span className="-mt-1">Avances sur salaire®</span>
                <span
                  className="bg-[#36b37e] text-white rounded-l-lg rounded-r-sm px-2 py-1 text-xs font-bold uppercase leading-5 sm:absolute sm:top-[-40px] sm:right-[-135px] md:right-[-110px] relative -top-14 -right-9 ml-2 sm:ml-0"
                  style={{
                    borderTopLeftRadius: "15px",
                    borderBottomLeftRadius: "15px",
                    borderTopRightRadius: "3px",
                    borderBottomRightRadius: "3px",
                  }}
                >
                  NOUVEAU
                </span>
              </div>
            }
            description="Un logiciel d'avances sur salaire innovant qui offre à vos employés des avances instantanées, intégrées de manière transparente dans le système de paie, avec un suivi en temps réel pour une gestion simplifiée et efficace."
            features={[
              "Avances instantanées en un clic",
              "Intégration automatisée fluide",
              "Suivi en temps réel des transactions",
            ]}
          />
        </div>
      </div>
    </section>
  );
}

export default PriceTable;
