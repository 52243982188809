import React from "react";
import Benefits from "../components/Benefits";
import Blogs from "../components/Blogs";
import BottomCTA from "../components/BottomCTA";
import BottomCTA2 from "../components/BottomCTA2";
import Hero from "../components/Hero";
import OurExperts from "../components/OurExperts";
import Partners from "../components/Partners";
import PriceTable from "../components/PriceTable";
import Testimonials from "../components/Testimonials";
import { useLang } from "../contexts/LangContext";
import { useSoftware } from "../contexts/SoftwareContext";

function Home() {
  const { isLangOpen, setIsLangOpen } = useLang();
  const { isSoftwareMenuOpen, setIsSoftwareMenuOpen } = useSoftware();
  return (
    <div
      onClick={() => {
        if (isLangOpen) {
          setIsLangOpen(false); // Fermer le menu si ouvert
        } else if (isSoftwareMenuOpen) {
          setIsSoftwareMenuOpen(false); // Fermer le menu si ouvert
        } else {
          // Logique alternative si le menu est fermé
          console.log("Le menu est déjà fermé");
        }
      }}
    >
      {/* <Header /> */}

      <Hero />

      <Partners />

      {/* <Features /> */}

      <Benefits />
      <PriceTable />

      <OurExperts />

      <Blogs />

      <Testimonials />

      <BottomCTA />
      <BottomCTA2 />

      {/* <Footer/> */}
    </div>
  );
}

export default Home;
