import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../footer.css";
const detectZoomLevel = () => {
  const ratio = window.devicePixelRatio || 1;
  if (ratio === 1.25) {
    return "scale-125";
  } else {
    return "scale-100";
  }
};
function Footer() {
  const [scaleClass, setScaleClass] = useState(detectZoomLevel());

  useEffect(() => {
    const handleResize = () => {
      setScaleClass(detectZoomLevel());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);  
  return (
    <footer className="hsg-footer">
      <div className={`hsg-footer__layout ${scaleClass}`}>
        <p className="p-4 sm:p-0 sm:pb-10">
          Une solution intuitive avec une équipe à votre service. Découvrez
          comment PaieNova simplifie la gestion d'entreprise et vous accompagne
          dans votre croissance grâce à nos outils et services essentiels.
        </p>
        <nav className="hsg-footer__nav">
          {/* <div className="hsg-footer__nav-left">
            <section className="hsg-footer__nav-column hsg-footer__nav-column-left">
              <h2
                className="hsg-footer__nav-heading font-bold pl-4 sm:p-0 sm:pb-4"
                role="presentation"
              >
                <button
                  className="hsg-footer__nav-toggle"
                  aria-expanded="false"
                >
                  Fonctionnalités
                </button>
              </h2>

              <ul>
                <h2 className="hsg-footer__nav-heading" role="presentation">
                  <button
                    className="hsg-footer__nav-toggle"
                    aria-expanded="false"
                  >
                    Gestion de la Paie et RH
                  </button>
                </h2>
                <li className="hsg-footer__nav-item" data-order="0">
                  <a
                    className="ga_nav_link "
                    href="https://www.hubspot.fr/products?hubs_content=www.hubspot.fr/&amp;hubs_content-cta=Toutes%20les%20fonctionnalit%C3%A9s"
                    data-order="0"
                    data-linkorder="0"
                    data-ga_nav_type="footer_nav"
                    data-ga_nav_tree_text="Gestion de la Paie et RH > Automatisation des salaires et des processus RH"
                  >
                    Automatisation des salaires et des processus RH
                  </a>
                </li>
                <h2 className="hsg-footer__nav-heading" role="presentation">
                  <button
                    className="hsg-footer__nav-toggle"
                    aria-expanded="false"
                  >
                    Comptabilité Intégrée
                  </button>
                </h2>
                <li className="hsg-footer__nav-item" data-order="1">
                  <a
                    className="ga_nav_link "
                    href="https://www.hubspot.fr/products/cms/ai-content-writer?hubs_content=www.hubspot.fr/&amp;hubs_content-cta=Assistant%20IA%20de%20contenu"
                    data-order="1"
                    data-linkorder="1"
                    data-ga_nav_type="footer_nav"
                    data-ga_nav_tree_text="Comptabilité Intégrée > Suivi des dépenses et génération de rapports financiers"
                  >
                    Suivi des dépenses et génération de rapports
                  </a>
                </li>
                <h2 className="hsg-footer__nav-heading" role="presentation">
                  <button
                    className="hsg-footer__nav-toggle"
                    aria-expanded="false"
                  >
                    Avances sur Salaire
                  </button>
                </h2>
                <li className="hsg-footer__nav-item" data-order="2">
                  <a
                    className="ga_nav_link "
                    href="https://www.hubspot.fr/products/sales/schedule-meeting?hubs_content=www.hubspot.fr/&amp;hubs_content-cta=Prise%20de%20rendez-vous%20en%20ligne"
                    data-order="2"
                    data-linkorder="2"
                    data-ga_nav_type="footer_nav"
                    data-ga_nav_tree_text="Avances sur Salaire > Flexibilité financière instantanée pour vos employés"
                  >
                    Flexibilité financière instantanée pour vos employés
                  </a>
                </li>
              </ul>
            </section>
          </div> */}
          <div className="hsg-footer__nav-right">
            <div className="hsg-footer__nav-full">
              <h2
                className="hsg-footer__nav-heading text-[#263BE3] pt-4 pl-4 sm:pt-4 sm:pl-0 md:pt-0 sm:pl-0 lg:pt-0 sm:pl-0"
                role="presentation"
              >
                <button
                  className="hsg-footer__nav-toggle font-semibold"
                  aria-expanded="false"
                >
                  Fonctionnalités
                </button>
              </h2>
              <section className="hsg-footer__nav-column ">
                <h2 className="hsg-footer__nav-heading" role="presentation">
                  <button
                    className="hsg-footer__nav-toggle"
                    aria-expanded="false"
                  >
                    Gestion de la Paie et RH
                  </button>
                </h2>
                <ul>
                  <li className="hsg-footer__nav-item" data-order="0">
                    <a
                      className="ga_nav_link "
                      href=""
                      data-order="0"
                      data-linkorder="0"
                      data-ga_nav_type="footer_nav"
                      data-ga_nav_tree_text="Gestion de la Paie et RH > Automatisation des salaires et des processus RH"
                    >
                      Automatisation des salaires et des processus RH
                    </a>
                  </li>
                </ul>
              </section>

              <section className="hsg-footer__nav-column ">
                <h2 className="hsg-footer__nav-heading" role="presentation">
                  <button
                    className="hsg-footer__nav-toggle"
                    aria-expanded="false"
                  >
                    Comptabilité Intégrée
                  </button>
                </h2>
                <ul>
                  <li className="hsg-footer__nav-item" data-order="0">
                    <a
                      className="ga_nav_link "
                      href=""
                      data-order="0"
                      data-linkorder="0"
                      data-ga_nav_type="footer_nav"
                      data-ga_nav_tree_text="Comptabilité Intégrée > Suivi des dépenses et génération de rapports financiers"
                    >
                      Suivi des dépenses et génération de rapports financiers
                    </a>
                  </li>
                </ul>
              </section>

              <section className="hsg-footer__nav-column ">
                <h2 className="hsg-footer__nav-heading" role="presentation">
                  <button
                    className="hsg-footer__nav-toggle"
                    aria-expanded="false"
                  >
                    Avances sur Salaire
                  </button>
                </h2>
                <ul>
                  <li className="hsg-footer__nav-item" data-order="0">
                    <a
                      className="ga_nav_link "
                      href=""
                      data-order="0"
                      data-linkorder="0"
                      data-ga_nav_type="footer_nav"
                      data-ga_nav_tree_text="Avances sur Salaire > Flexibilité financière instantanée pour vos employés"
                    >
                      Flexibilité financière instantanée pour vos employés
                    </a>
                  </li>
                </ul>
              </section>
            </div>
            <div className="hsg-footer__nav-full ml-0 sm:ml-0 md:ml-0 lg:ml-10">
              <h2
                className="hsg-footer__nav-heading text-[#263BE3] pt-4 pl-4 sm:pt-4 sm:pl-0 md:pt-0 sm:pl-0 lg:pt-0 sm:pl-0"
                role="presentation"
              >
                <button
                  className="hsg-footer__nav-toggle font-semibold"
                  aria-expanded="false"
                >
                  Support Client
                </button>
              </h2>
              <section className="hsg-footer__nav-column ">
                <h2 className="hsg-footer__nav-heading" role="presentation">
                  <button
                    className="hsg-footer__nav-toggle"
                    aria-expanded="false"
                  >
                    Assistance 24/7
                  </button>
                </h2>
                <ul>
                  <li className="hsg-footer__nav-item" data-order="0">
                    <a
                      className="ga_nav_link "
                      href=""
                      data-order="0"
                      data-linkorder="0"
                      data-ga_nav_type="footer_nav"
                      data-ga_nav_tree_text="Assistance 24/7 > Notre équipe est disponible à tout moment par téléphone, chat ou e-mail"
                    >
                      Notre équipe est disponible à tout moment par téléphone,
                      chat ou e-mail
                    </a>
                  </li>
                </ul>
              </section>
            </div>
            <div className="w-[1px] h-full bg-gray-500 ml-0 mr-0"></div>
            <div className="hsg-footer__nav-full ml-0 sm:ml-0 md:ml-0 lg:ml-10">
              <h2
                className="hsg-footer__nav-heading text-[#263BE3] pt-4 pl-4 sm:pt-4 sm:pl-0 md:pt-0 md:pl-0 lg:pt-0 lg:pl-0"
                role="presentation"
              >
                <button
                  className="hsg-footer__nav-toggle font-semibold"
                  aria-expanded="false"
                >
                  Ressources Gratuites
                </button>
              </h2>
              <section className="hsg-footer__nav-column ">
                <h2 className="hsg-footer__nav-heading" role="presentation">
                  <button
                    className="hsg-footer__nav-toggle"
                    aria-expanded="false"
                  >
                    Guides et Modèles
                  </button>
                </h2>
                <ul>
                  <li className="hsg-footer__nav-item" data-order="0">
                    <a
                      className="ga_nav_link "
                      href=""
                      data-order="0"
                      data-linkorder="0"
                      data-ga_nav_type="footer_nav"
                      data-ga_nav_tree_text="Guides et Modèles > Accédez à des guides pratiques et modèles personnalisables pour optimiser vos opérations"
                    >
                      Accédez à des guides pratiques et modèles personnalisables
                      pour optimiser vos opérations
                    </a>
                  </li>
                </ul>
              </section>
            </div>
            <div className="hsg-footer__nav-split">
              <h2
                className="hsg-footer__nav-heading text-[#263BE3] pt-4 pl-4 sm:pt-4 sm:pl-0 md:pt-0 sm:pl-0 lg:pt-0 sm:pl-0"
                role="presentation"
              >
                <button
                  className="hsg-footer__nav-toggle font-semibold"
                  aria-expanded="false"
                >
                  Entreprise
                </button>
              </h2>
              <section className="hsg-footer__nav-column ">
                <h2 className="hsg-footer__nav-heading" role="presentation">
                  <button
                    className="hsg-footer__nav-toggle"
                    aria-expanded="false"
                  >
                    À propos de PaieNova
                  </button>
                </h2>
                <ul>
                  <li className="hsg-footer__nav-item" data-order="0">
                    <a
                      className="ga_nav_link "
                      href=""
                      data-order="0"
                      data-linkorder="0"
                      data-ga_nav_type="footer_nav"
                      data-ga_nav_tree_text="À propos de PaieNova > Découvrez notre mission, notre équipe et comment nous aidons les entreprises à réussir"
                    >
                      Découvrez notre mission, notre équipe et comment nous
                      aidons les entreprises à réussir
                    </a>
                  </li>

                  {/* <li className="hsg-footer__nav-item" data-order="2">
                    <a
                      className="ga_nav_link hsg-en-link"
                      href="https://www.hubspot.com/hubspot-user-groups?hubs_content=www.hubspot.fr/&amp;hubs_content-cta=hsg-en-link&amp;__hstc=192971698.99a33abc49b597b10cbea4085c8215dd.1716316688192.1717497320600.1717511673070.23&amp;__hssc=192971698.1.1717511673070&amp;__hsfp=2080785307"
                      data-order="2"
                      data-linkorder="2"
                      data-ga_nav_type="footer_nav"
                      data-ga_nav_tree_text="Clients > Groupes d'utilisateurs PaieNova"
                    >
                      Groupes d'utilisateurs PaieNova
                    </a>
                  </li> */}
                </ul>
              </section>
            </div>
          </div>
        </nav>
      </div>

      <section className="hsg-footer__contact-links ">
        <ul className="hsg-footer__social">
          <li>
            <Link to="https://linkedin.com/company/paienova">
              <img
                classNameName="icon-button"
                src="./images/PaieNova-linkedin.png"
                alt="PaieNova-linkedin"
                width="50px"
              />
            </Link>
          </li>
          <li>
            <Link>
              <img
                classNameName="icon-button"
                src="./images/PaieNova-twitter.png"
                alt="PaieNova-twitter"
                width="50px"
              />
            </Link>
          </li>
          <li>
            <Link>
              <img
                classNameName="icon-button"
                src="./images/PaieNova-facebook.png"
                alt="PaieNova-facebook"
                width="50px"
              />
            </Link>
          </li>
          <li>
            <Link>
              <img
                classNameName="icon-button"
                src="./images/PaieNova-instagram.png"
                alt="PaieNova-instagram"
                width="50px"
              />
            </Link>
          </li>
        </ul>
      </section>

      <section className="hsg-footer__bottom">
        <div className="hsg-footer__logo p-5 gap-5">
          <Link to="/">
            <img
              classNameName="md:hidden lg:inline-flex -mt-9"
              src="./images/PaieNova_logo_white.png"
              alt="PaieNova Logo white"
              width="150"
            />
          </Link>
          <p className="mt-5 sm:mt-0 md:mt-0 lg:mt-0">Copyright © 2024 PaieNova.</p>
        </div>
        <div className="hsg-footer__copyright">
          <ul>
            <li>
              <Link to="/cgu">Mentions légales</Link>
            </li>
            <li>
              <Link to="/privacy">Politique de confidentialité</Link>
            </li>
          </ul>
        </div>
      </section>
    </footer>
  );
}

export default Footer;

export function Linked({ text }) {
  return <p classNameName="cursor-pointer mt-2">{text}</p>;
}
