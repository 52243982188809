import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLang } from "../contexts/LangContext";
import { useSoftware } from "../contexts/SoftwareContext";
import "../index.css";
function Header() {
  // const navItems = [];
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const [isLangOpen, setIsLangOpen] = useState(false);
  const { isLangOpen, setIsLangOpen } = useLang();
  const { isSoftwareMenuOpen, setIsSoftwareMenuOpen } = useSoftware();
  // const [isSoftwareMenuOpen, setIsSoftwareMenuOpen] = useState(false);
  const [isResourcesMenuOpen, setIsResourcesMenuOpen] = useState(false);

  const [showTopPart, setShowTopPart] = useState(true);

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        // Scrolling down
        setShowTopPart(false);
      } else {
        // Scrolling up
        if (currentScrollTop === 0) {
          setShowTopPart(true);
        }
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="fixed top-0 left-0 w-full z-50"
      onClick={() => {
        if (isLangOpen) {
          setIsLangOpen(false); // Fermer le menu si ouvert
        } else if (isSoftwareMenuOpen) {
          setIsSoftwareMenuOpen(false); // Fermer le menu si ouvert
        } else {
          // Logique alternative si le menu est fermé
          console.log("Le menu est déjà fermé");
        }
      }}
    >
      {/* Premier menu */}

      <div
        className={`bg-white py-2 transition-transform duration-300 shadow-sm ${
          showTopPart ? "translate-y-0" : "hidden"
        }`}
      >
        <div className="container mx-auto flex justify-between items-center px-6 md:px-8">
          <div>
            <div className="font-semibold">
              <p
                className="nav-item cursor-pointer flex items-center -ml-2 sm:ml-0 md:ml-0 lg:ml-0"
                onClick={() => setIsLangOpen(!isLangOpen)}
              >
                <img
                  className="md:inline-flex lg:inline-flex h-5 w-5"
                  src="./images/PaieNova-globe-earth.png"
                  alt="Logo Full"
                  // width="150px"
                />
                <span className="ml-2 text-[#212121]">Français</span>
                <span className="ml-2">
                  {isLangOpen ? (
                    <ChevronUpIcon className="h-5 w-5" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" />
                  )}
                </span>
              </p>
              {isLangOpen && (
                <div className="absolute w-36 h-25 top-10 text-center bg-white shadow-lg rounded-sm z-10">
                  <Link
                    to="/"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    English
                  </Link>
                  <Link
                    to="/"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Espagnol
                  </Link>
                  <Link
                    to="/"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Français
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="md:flex items-center text-[#212121] space-x-6">
            <Link to="https://app.paienova.com/" className="nav-item font-semibold">
              Se connecter
            </Link>
          </div>
        </div>
      </div>
      {/* Second menu */}
      <section className="bg-white shadow-lg">
        <header className="container flex h-20 justify-between shadow-md md:shadow-none">
          <Link to="/">
            <div className="flex items-center">
              <img
                className="md:hidden lg:inline-flex mt-6"
                src="./images/PaieNova_logo.png"
                alt="PaieNova Logo"
                width="130px"
              />
            </div>
          </Link>
          <img
            className="hidden md:inline-block lg:hidden"
            src="./images/PaieNova_logo.png"
            alt="PaieNova Logo"
            width="100px"
          />
          <div className="flex items-center">
            {/* <MenuIcon className="h-10 md:hidden" /> */}
            <button
              onClick={toggleMenu}
              // data-collapse-toggle="mobile-menu-2"
              type="button"
              class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded={isMenuOpen}
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class={`w-6 h-6 ${isMenuOpen ? "hidden" : "block"}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                class={`w-6 h-6 ${isMenuOpen ? "block" : "hidden"}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <div
              className={`${
                isMenuOpen && showTopPart ? "flex" : "hidden"
              } flex-col items-start gap-3 md:gap-0 pb-5 pl-9 md:pb-0 md:pl-10 md:items-center absolute font-medium justify-between pt-5 top-28 sm:pt-0 sm:top-28 md:top-28 lg:top-0 left-0 w-full h-auto bg-white md:static md:flex md:flex-row md:h-auto md:bg-transparent lg:space-x-4`}
              id="mobile-menu-2"
            >
              <p
                className="nav-item cursor-pointer flex items-center pl-0 md:pl-0"
                onClick={() => setIsSoftwareMenuOpen(!isSoftwareMenuOpen)}
              >
                Logiciels
                <span className="ml-2">
                  {isSoftwareMenuOpen ? (
                    <ChevronUpIcon className="h-5 w-5" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" />
                  )}
                </span>
              </p>
              {isSoftwareMenuOpen && (
                <div className="absolute left-8 sm:left-8 md:left-44 lg:left-96 mt-10 sm:mt-10 md:mt-60 lg:mt-60 w-60 bg-white shadow-lg rounded-sm z-10">
                  <Link
                    to="#pricing"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    RH
                  </Link>
                  <Link
                    to="/#pricing"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Paie
                  </Link>
                  <Link
                    to="/#pricing"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Comptabilité
                  </Link>
                  <Link
                    to="/#pricing"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Avances sur salaire
                  </Link>
                </div>
              )}

              <Link to="/contact">
                <p className="nav-item pl-0 md:pl-0">Tarification</p>
              </Link>

              <p
                className="nav-item cursor-pointer flex items-center pr-0 sm:pr-20 md:pr-20 -ml-4 -sm:ml-4 md:ml-4 lg:ml-0"
                // onClick={() => setIsResourcesMenuOpen(!isResourcesMenuOpen)}
              >
                <Link to="#ressources">Ressources</Link>
                {/* <span className="ml-2">
                  {isResourcesMenuOpen ? (
                    <ChevronUpIcon className="h-5 w-5" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" />
                  )}
                </span> */}
              </p>
              {isResourcesMenuOpen && (
                <div className="absolute grid justify-items-stretch left-[650px] mt-48 sm:mt-[200px] md:mt-[200px] lg:mt-[200px] w-48 sm:w-48 md:w-48 lg:w-48 bg-white shadow-lg rounded-sm z-10">
                  <Link
                    to="/software1"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Ressources 1
                  </Link>
                  <Link
                    to="/software2"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Ressources 2
                  </Link>
                  <Link
                    to="/software3"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Ressources 3
                  </Link>
                </div>
              )}

              {/* </div> */}

              <Link to="/contact">
                <button className="primary-button2 text-sm">
                  Demandez une démo
                </button>
              </Link>
              <Link to="https://app.paienova.com/">
                <button className="primary-button-white text-sm">
                  Essayez gratuitement
                </button>
              </Link>
            </div>
          </div>
        </header>
      </section>
    </div>
  );
}

export default Header;
