import React from "react";
import { Link } from "react-router-dom";

function OurExperts() {
  return (
    <section className="bg-[#E9EBFC] p-8 sm:p-12 md:p-24">
      <div className="container mx-auto px-4 md:px-24 md:flex grid items-center justify-between">
        <div>
          <h1 className="text-2xl sm:text-3xl md:text-[21px] font-bold">
            Découvrez le nouveau Service PaieNova Révolutionnaire
          </h1>
          <p className="mt-2 max-w-4xl">
            PaieNova transforme la gestion de la paie, des RH, de la
            comptabilité et des avances sur salaire instantanées grâce à
            l'intelligence artificielle. Simplifiez vos processus, réduisez les
            erreurs et offrez à vos employés une flexibilité financière inégalée
            avec notre suite complète d'outils.
          </p>
        </div>

        {/* <div className="mx-auto mt-8 max-w-5xl grid gap-8 md:grid-cols-2">
          <ExpertItem
            imgSrc="./images/Avator.png"
            text="I can take care of your pitch"
          />
          <ExpertItem
            imgSrc="./images/Avator-1.png"
            text="I can design you website"
          />
          <ExpertItem
            imgSrc="./images/Avator-2.png"
            text="I can help marketing strategy"
          />
          <ExpertItem
            imgSrc="./images/Avator-3.png"
            text="I can prototype your app"
          />
        </div> */}
        <Link to="/contact">
          <button className="primary-button-white mt-10 md:mt-0 text-sm h-14 w-full">
            En savoir plus
          </button>
        </Link>
      </div>
    </section>
  );
}

export default OurExperts;

function ExpertItem({ imgSrc, text }) {
  return (
    <div className="max-w-md flex items-center even:flex-row-reverse md:flex-row-reverse md:first:flex-row md:last:flex-row">
      <img src={imgSrc} alt="" width="98px" />

      <div className="shadow-lg px-8 py-3 mx-2 max-w-[270px] md:max-w-none text-left rounded-full flex justify-center items-center mt-1.5">
        <p className="font-medium">{text}</p>
      </div>
    </div>
  );
}
