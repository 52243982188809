import React from "react";
import { Link } from "react-router-dom";

function BlogItem({ imgSrc, title, description }) {
  return (
    <div className="border text-left rounded-xl hover:shadow-lg active:shadow-none cursor-pointer hover:scale-105 active:scale-95 transition">
      <div className="aspect-w-16 aspect-h-9">
        <img
          className="rounded-t-xl h-full w-full object-cover"
          src={imgSrc}
          alt=""
        />
      </div>
      <div className="px-4 pt-6 pb-10 flex flex-col justify-between h-96">
        {/* <p className="text-base">
          By{" "}
          <span className="font-bold hover:text-primary transition ease-out">
            {author}
          </span>{" "}
          | {date}
        </p> */}
        <div>
          <h3 className="font-semibold text-2xl mt-3 h-24 transition ease-out">
            {title}
          </h3>
          <p className="text-base mt-4 h-36">{description}</p>
        </div>
        <div className="text-center">
          <Link to="/contact">
            <button className="primary-button2 text-sm h-14 mt-3 w-full">
              En savoir plus
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BlogItem;
